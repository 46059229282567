import { useEffect, useState } from "react";
import InputA from "../../components/InputA/InputA";
import { Edit2, Type } from "react-feather";
import './H2Edit.scss'
import { parseStringFromServer } from "../../services/TextConverter";

enum Status {
  standard,
  prepareEdit,
  edit,
  prepareStandard,
}

export default function H2Edit(props: {
  value: string,
  onChange: (newValue: string) => void,
  className?: string,
  startEdit?: () => void,
  endEdit?: () => void,
  usePromt?: boolean,
  resetToOldValue?: () => void,
  oldValue?: string,
  prefix?: string,
}) {
  const [status, setStatus] = useState(Status.standard)
  const [value, setValue] = useState(props.value)
  const [oldValue, setOldValue] = useState(props.oldValue)
  useEffect(() => {
    setValue(props.value)
    setOldValue(props.oldValue)
  }, [props.value, props.oldValue])
  function toEdit() {
    if (props.usePromt) {
      const nv = window.prompt('Neuen Tag-Namen angeben', value)
      if (!nv) { return }
      props.onChange(nv)
      return
    }
    if (props.startEdit) {
      props.startEdit()
    }
    setStatus(Status.prepareEdit)
    setTimeout(() => {
      setStatus(Status.edit)
    }, 200)
  }

  function toStandard() {
    setStatus(Status.prepareStandard)
    setTimeout(() => {
      setStatus(Status.standard)
    }, 200)
    if (props.endEdit) {
      props.endEdit()
    }
  }
  
  return <h2
    className={`H2Edit ${props.className || ''} h2Edit-status-${Status[status]}`}
  >
    <div
      className='h2EditTitleHolder'
    >
      {
        status !== Status.edit &&
        <span className='h2EditTitle'>
          {props.prefix ? `${props.prefix}: ` : ''}
          {value}
          <Edit2
            className='editButton'
            size="16px"
            onClick={toEdit}
          />
          { oldValue !== undefined &&
            <>
              <Type className='hover-show-next' />
              <div className='hover-show-next-target'>
                {
                  oldValue &&
                  <div>Wert aus Vorlage:
                    <div className='w3-padding w3-border w3-margin-top w3-margin-bottom' dangerouslySetInnerHTML={{__html: parseStringFromServer(oldValue)}}>
                    </div>
                  </div>
                }
                {
                  props.resetToOldValue &&
                  <button
                    onClick={props.resetToOldValue}
                  >Lokale Änderung verwerfen</button>
                }
              </div>
            </>
          }
        </span>
        
      }
    </div>
    <div
      className='h2EditInputHolder'
    >
      {
        status !== Status.standard &&
        <InputA
          value={value}
          className='h2EditInput'
          returnVal={(newValue) => {
            setValue(newValue)
            props.onChange(newValue)
            toStandard()
          }}
        />
      }
    </div>
  </h2>
}
