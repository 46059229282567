import { useEffect, useState } from "react"
import ItemPersonDTO from "../../DTO/ItemPersonDTO"

export default function MailtoButton(props: {
  items: ItemPersonDTO[],
  whitelist?: number[],
  className?: string,
}) {
  const [emailList, setEmailList] = useState<string[]>([])

  useEffect(() => {
    console.log('receive info', props.items, props.whitelist)
    if (props.whitelist !== undefined) {
      setEmailList(props.items.filter(item => (props.whitelist || []).includes(item.id)).map(item => item.email).filter(mail => mail))
    } else {
      setEmailList(props.items.map(item => item.email).filter(mail => mail))
    }
  }, [props.items, props.items.length, props.whitelist, props.whitelist?.length])
  return <a className={props.className || 'w3-button'} href={`mailto:?bcc=${emailList.join(',')}`}>
    Email {emailList.length}
  </a>
}

