import { useEffect, useState } from "react";
import { MItem } from "../Menu";
import { Status } from "../day/dayView";
import Spinner from "../../../components/Spinner/Spinner";
import ItemPersonDTO from "../../DTO/ItemPersonDTO";
import mainservice from "../../../services/MainService";
import { EventTag, TrainerTypes } from "./Person";
import { parseStringFromServer } from "../../../services/TextConverter";
import EventList from "../Event/EventList";
import { CheckSquare, Square, Trash } from "react-feather";
import CustomerList from "../Customer/CustomerList";
import { PersonKind } from "./PersonKind";
import PlaningToolRoot from "../PlaningToolRoot";
import { RightDrawerContent } from "../RightDrawer";
import BroadcastRightButton from "../../elements/BroadcastRightButton";
import { PersonBubble } from "./PersonBubble";
import { ColorPickerDropDown } from "../tags/TagOrganizer";
import Debounce from "../../../services/Debounce";
import { PublishKind } from "../../models/publishKinds";
import EditFieldsPrinter from "../../editFields/EditFieldsPrinter";
import InputB from "../../../components/InputB/InputB";
import LogView from "../Generic/LogView";

export default function PersonEdit(props: {

}) {
  const id = mainservice.nav.getVal('id') as number
  const [status, setStatus] = useState(Status.loading)
  const [RerenderEvents, setRerenderEvents] = useState(0)
  const [EditId, setEditId] = useState(-1)
  const [RerenderCustomers, setRerenderCustomers] = useState(0)
  const [Item, setItem] = useState(new ItemPersonDTO({
    id: id || -1
  }))
  const [UserMsg, setUserMsg] = useState('')
  const broadCastBackKeyEventAdd = `PersonEventAddFor-${Item.id}`
  const broadCastBackKeyCustomerAdd = `PersonCustomerAddFor-${Item.id}`
  const broadCastBackKeyCustomerLocationAdd = `PersonCustomerLocationAddFor-${Item.id}`
  mainservice.registerToBroadcast(`PersonEdit-${Item.id}`,
    async (key: string, _value: any) => {
      if (key === broadCastBackKeyEventAdd) {
        setStatus(Status.loading)
        await Item.addEvent(_value.id as number)
        setStatus(Status.standard)
      }
      if (key === broadCastBackKeyCustomerAdd) {
        setStatus(Status.loading)
        await Item.addCustomer(_value.id as number)
        setStatus(Status.standard)
      }
      if (key === broadCastBackKeyCustomerLocationAdd) {
        setStatus(Status.loading)
        await Item.addCustomerLocation(_value.id as number)
        setStatus(Status.standard)
      }
    }
  )
  function init () {
    Item.getFromServer().then(() => {
      setStatus(Status.standard)
    }).catch((err) => {
      console.log('err', err)
      setUserMsg(err.description)
    })
  }
  useEffect(() => {
    init()
  }, [])
  function WarnMsg() {
    return <>
      {
      UserMsg &&
      <div
        className={`w3-border w3-border-yellow w3-padding`}
      >
        {UserMsg}
        <button
          onClick={() => {
            setUserMsg('')
          }}
          className={`w3-button w3-grey`}
        >OK</button>
      </div>
    }
    </>
  }

  return <PlaningToolRoot standalone={true} menuItem={MItem.persons} topMenu={<h2>Person - {Item.getDisplayName()}</h2>}>
    <div className='contentHeader flex justify-content-space-between'>
      <h1>Person - {Item.getDisplayName()}</h1>
      <PersonBubble item={Item}/>
    </div>
    <WarnMsg />
    { status === Status.loading &&
      <Spinner />
    }
    { status !== Status.loading &&
    <>
      <div className='contentPart w3-row'>

        <div className='w3-col s12 m12 l4'>
          <div
            className={`paddingTop w3-container`}
          >
          <div className='EditField'>
            <label className='EditFieldLabel'>Personennummer</label>
          <div>{Item.getPersonNumber()}</div>
          </div>
            <div className='EditField'>
              <label className='EditFieldLabel'>Vorname</label>
              <InputB
                value={parseStringFromServer(Item.firstname || '')}
                returnVal={async (rv) => {
                  setEditId(-2)
                  await Item.setFirstname(rv)
                  setEditId(-1)
                }}
              />
            </div>
            <div className='EditField'>
              <label className='EditFieldLabel'>Nachname</label>
              <InputB
                value={parseStringFromServer(Item.lastname || '')}
                returnVal={async (rv) => {
                  setEditId(-3)
                  await Item.setLastname(rv)
                  setEditId(-1)
                }}
              />
            </div>
            <div className='EditField'>
              <label className='EditFieldLabel'>EMail</label>
              <InputB
                value={parseStringFromServer(Item.email || '')}
                returnVal={async (rv) => {
                  setEditId(-4)
                  await Item.setName(rv)
                  setEditId(-1)
                }}
              />
            </div>
            <EditFieldsPrinter
              fields={Item.getEditFields('personProf')}
              item={Item}
            />
          </div>
        </div>
        <div className='w3-col s12 m12 l8 w3-margin-top'>
          <CustomerList
            personId={Item.id}
            noSearch={true}
            rerenderTrigger={RerenderCustomers}
            onRemove={async (id) => {
              await Item.removeCustomer(id)
              setRerenderCustomers(RerenderCustomers + 1)
            }}
            headline={'Unternehmen'}
            options={[['customerShowAddress', 'yes'], ['customerPrefixAddress', Item.getDisplayName()]]}
          />
          <BroadcastRightButton
            contentType={RightDrawerContent.selectCustomer}
            broadCastBackKey={broadCastBackKeyCustomerAdd}
            title={`Einem Unternehmen zuordnen`}
          />
          <EditFieldsPrinter
            fields={Item.getEditFields('personPhone')}
            item={Item}
          />
        </div>
      </div>

      <div className='contentPart'>
        <div className='contentHeader'>
          <h2>Details</h2>
        </div>
        <div className='w3-container paddingTop'>
          <EditFieldsPrinter
            fields={Item.getEditFields()}
            item={Item}
          />
        </div>
      </div>

      {
        Item.trainer > -1 &&
        <div className='contentPart'>
          <EventList
            personId={Item.id}
            noSearch={true}
            noNew={true}
            rerenderTrigger={RerenderEvents}
            allowRemove={true}
            personKind={PersonKind.trainer}
            headline={'Veranstaltungen - als Trainer'}
            publishKind={PublishKind.isSeminar}
            ignoreDate='ignore'
          />
        </div>
      }

      <div className='contentPart'>
        <EventList
          personId={Item.id}
          noSearch={true}
          noNew={true}
          rerenderTrigger={RerenderEvents}
          allowRemove={true}
          headline={'Veranstaltungen'}
          publishKind={PublishKind.isSeminar}
          ignoreDate='ignore'
        />
        <BroadcastRightButton
          contentType={RightDrawerContent.selectEvent}
          broadCastBackKey={broadCastBackKeyEventAdd}
          title={`Weitere Veranstaltung zuordnen`}
        />
      </div>

      <div className='contentPart' style={{minHeight: '400px'}}>
        <div className='contentHeader'>
          <h2>
            Sonstiges
          </h2>
        </div>
        <div className='w3-padding floatRight'>
          <label>Farbe</label>
          <ColorPickerDropDown
            currentColor={Item.nameToColor()}
            changeColor={(c) => {
              Debounce(`personColor-${Item.id}`, async () => {
                Item.addProp('data', 'color', c)
              }).catch(() => {})
            }}
          />
        </div>
        <div className='w3-padding'>
          <Check
            name="Ist Trainer"
            value={Item.trainer}
            onCheck={async (v) => {
              await Item.setTrainer(v)
            }}
          />
          <div className='w3-margin-top'>
            <EventTag
              person={Item}
              parent={Item}
              className='w3-margin-left w3-margin-top w3-margin-bottom'
              key1={'trainerProp'}
              key2={'StandardRole'}
              standardValue={'trainer'}
              types={TrainerTypes}
            />
            <label className='w3-margin-left'>Standard Rolle</label>
          </div>
          
        </div>
        <LogView getLogs={async () => await Item.getLogs()} className='w3-margin-top'/>
        <button
          className={`w3-button w3-red`}
          onClick={async () => {
            if (!window.confirm(`Person ${Item.name} wirklich löschen?`)) { return } 
            await Item.delete()
            mainservice.navTo([['view', 'spPersons']])
          }}
        >Löschen</button>
      </div>
    </>
    }
  </PlaningToolRoot>
}

function Check(props: {
  name: string,
  value: number,
  onCheck: (i: number) => void,
}) {
  const [value, setValue] = useState(props.value)
  return <div
    onClick={() => {
      const newValue = value === 1 ? 0 : 1
      setValue(newValue)
      props.onCheck(newValue)
    }}
    className={`flex`}
  >
    <div className={`pointer`}>
      {
        (value && value > 0) ?
        <CheckSquare /> : <Square />
      }
    </div>
    <div
      className={`flex-grow`}
    >{props.name}</div>
  </div>
}