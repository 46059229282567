import BookDTO, {IBook, Layer} from '../DTO/BookDTO'

type PermanameType = {
  permaname: string,
  view: string,
  id: number
}

const permanames: PermanameType[] = [
  {
    permaname: 'fuehrungsstilanalyse',
    view: 'book',
    id: 17
  },
  {
    permaname: 'new-normal',
    view: 'book',
    id: 19
  }
]

class Permanames {
  permanames = new Map()
  constructor() {
    permanames.forEach((p: PermanameType) => {
      let pEntry = new Map()
      pEntry.set('view', p.view)
      pEntry.set('book', p.id)
      this.permanames.set(p.permaname, pEntry)
    })
  }

  public init(books: IBook[]) {
    books.forEach((b: IBook) => {
      const B = new BookDTO(b)
      const shorthand = B.getPropVal1('url', 'shorthand', '-')
      if (shorthand.length > 3 && shorthand.search(/^(view|admin|login|book|page)$/) === -1) {
        let pEntry = new Map()
        pEntry.set('view', 'book')
        pEntry.set('book', B.id)
        pEntry.set('permalink', shorthand)
        this.permanames.set(shorthand, pEntry)
      }
    })
  }

  has(key: string) {
    return this.permanames.has(key)
  }
  getVal(key: string): boolean | string | number {
    
    let okay = ''
    // let info: {has: any}
    this.permanames.forEach((_entry, permaname: string) => {
      if (window.location.href.search(permaname) > -1) {
        okay = permaname
        // info = entry
      }
    })
    if (!okay) {
      console.log('getVal not permaname', key, false)
      return false
    }
    const info = this.permanames.get(okay)
    if (!info.has(key)) {
      console.log('getVal not there', key, false)
      return false
    }
    const res = info.get(key)
    console.log('getVal result', key, res)
    return res
  }
}

export default class NavService {
  history: ([string, string|number][])[] = []
  state: any
  staticUrls: string[] = []
  permanames = new Permanames()
  knownItems = new Map([
    ['view', {default: 'base', type: 'string'}],
    ['book', {default: 0, type: 'number'}],
    ['page', {default: 0, type: 'number'}],
    ['pageId', {default: 0, type: 'number'}],
    ['copies', {default: 1, type: 'number'}],
    ['id', {default: -1, type: 'number'}],
    ['uid', {default: '', type: 'string'}],
    ['type', {default: '', type: 'string'}],
    ['doublePageId', {default: 0, type: 'number'}],
    ['session', {default: '', type: 'string'}],
    ['permalink', {default: '', type: 'string'}],
    ['permaname', {default: '', type: 'string'}],
    ['percentage', {default: 0, type: 'number'}],
  ])

  constructor() {
    this.resetState()
  }

  public init(books: IBook[], staticUrls: string[]) {
    this.staticUrls = staticUrls
    this.permanames.init(books)
    console.log('Route - init')
    /*
    window.onpopstate = () => {
      console.log('route go back')
      setTimeout(() => {
        this.resetState()
        this.getRoute()
        this.broadcast('nav', {view: this.nav.getVal('view')})
      }, 1000)
    };
    */
  }

  resetState() {
    this.state = new Map()
  }

  public setRoute(n: [string, string|number][]) {
    console.log('setRoute')
    this.history.push(n)
    // preserve things???
    this.state = new Map(n)
    this.writeRoute()
  }

  public historyBack() {
    console.log('HISTORY BACK')
    this.history.forEach(h => console.log('HISTORY', h))
    const thisThing = this.history.pop()
    const lastThing = this.history.pop()
    if (!lastThing) { return [] }
    // this.setRoute(lastThing)
    return lastThing
  }

  public historyBackName() {
    const lastItem = this.history[this.history.length - 2]
    if (!lastItem) { return '' }
    const viewItem = lastItem.find(i => i[0] === 'view')
    if (!viewItem) { return '' }
    const view = viewItem[1]
    switch(view) {
      case 'book':
        return 'Zurück zum Buch'
      case 'dashboard':
        return 'Zurück zum Dashboard'
      case 'library':
        return 'Zurück zu den Workbooks'
      case 'collectibleFlashcard':
        return 'Zurück zu den Lernkarten'
      case 'shop':
        return 'Zurück zum Shop'
      default:
        return 'Zurück'
    }
  }

  private writeRoute(replaceLastEntry?: boolean) {
    let newParts = '/'
    console.log('writeRoute', this.state)
    this.state.forEach((value: string | number, key: string) => {
      if (key === 'permaname' && this.permanames.has(value + '')) {
        newParts += `${value}/`
      } else if (value) {
        newParts += `${key}=${value}/`
      }
      return ''
    })
    if (window.location.pathname != newParts) {
      if (replaceLastEntry) {
        window.history.replaceState({url: newParts}, newParts, newParts)
      } else {
        window.history.pushState({url: newParts}, newParts, newParts)
      }
    }
  }

  setPage(page: number) {
    this.state.set('page', parseInt(`${page}`, 10))
    this.state.set('doublePageId', 0)
    this.state.set('pageId', 0)
    this.state.set('percentage', 0)
    this.writeRoute()
  }

  // New URL Modes:
  // /VIEW
  // /book/PAGE/SESSION

  public getRoute(): any {
    console.log('ROUTE')
    // Check if we have a static page:
    const pathName = window.location.pathname
    let foundPathName = ''
    if ((pathName === '' || pathName === '/') && window.location.href.search('fuehrungsstilanalyse.') === -1) {
      foundPathName = this.staticUrls.find(u => u.search('LANDING') === 0) || ''
    } else {
      foundPathName = this.staticUrls.find(u => pathName.search(u) === 1) || ''
    }
    if (foundPathName) {
      console.log('got static route', foundPathName)
      this.state.set('view', 'static')
      this.state.set('permaname', foundPathName)
      return this.state
    }
    // Use general Parts:
    let parts = window.location.pathname.split('/')
    if (parts.length === 0) {
      parts = window.location.hash.replace('#', '').split(';')
    }
    this.resetState()
    parts.forEach((h: string) => {
      if (this.permanames.has(h)) {
        this.state.set('permaname', h)
        return
      }
      const kv = h.split('=')
      // Only save the keys we know:
      const knownItem = this.knownItems.get(kv[0])
      if (knownItem) {
        if (knownItem.type === 'number') {
          this.state.set(kv[0], parseInt(kv[1], 10))
        } else {
          this.state.set(kv[0], kv[1])
        }
      }
    })
    return this.state
  }

  // Determins if a menu point is active by given route parameters
  public isActive(data: [string, string][]): boolean {
    const notActiveRoute = data.some((dp) => {
      const v = this.getVal(dp[0])
      if (v != dp[1]) {return true}
    })
    return !notActiveRoute
  }

  public setVal(key: string, value: number|string, replaceLastEntry?: boolean) {
    this.state.set(key, value)
    this.writeRoute(replaceLastEntry)
  }

  private returnInRightFormat(key: string, value: any) {
    const keyInfo = this.knownItems.get(key)
    if (!keyInfo) { return value }
    if (key === 'percentage' ) { return parseFloat(value)}
    if (keyInfo.type === 'number') { return parseInt(value, 10)}
    return value
  }

  public getVal(key: string): number|string {
    const stateVal = this.state.get(key)
    if (stateVal) {
      return this.returnInRightFormat(key, stateVal)
    }
    const altF = this.permanames.getVal(key)
    if (altF !== false) {
      return this.returnInRightFormat(key, altF)
    }
    const alt = this.knownItems.get(key)
    if (alt) {
      // define if we do not have values:
      return this.returnInRightFormat(key, alt.default)
    }
    // never reached:
    return ''
  }
}
