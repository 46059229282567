import { useEffect, useState } from "react"
import { X } from "react-feather"

export default function InputB (props: {
  returnVal: (value: string) => void,
  value?: string,
  delay?: number,
  className?: string,
  placeholder?: string,
  clearButton?: boolean,
  autofocus?: boolean,
  type?: 'singleline' | 'textarea' | 'date' | 'time',
  rows?: number,
  sendButton?: string,
  disabled?: boolean,
  multiline?: boolean,
}) {
  const [timeoutHolder, setTimeoutHolder] = useState(setTimeout(() => {}, 500))
  const [currentValue, setCurrentValue] = useState(props.value || '')
  const [lastSendValue, setLastSendValue] = useState(props.value || '')
  useEffect(() => {
    if (props.value !== undefined) {
      setCurrentValue(props.value)
      setLastSendValue(props.value)
    }
    // console.log('a', props.value)
  }, [props.value])
  return <>
    {
      props.type !== 'textarea' &&
      <input
        className={`w3-input ${props.className || ''} ${currentValue !== lastSendValue ? 'w3-yellow' : ''}`}
        value={currentValue}
        placeholder={props.placeholder}
        autoFocus={!!props.autofocus}
        onChange={(event) => {
          const value = event.target.value
          setCurrentValue(value)
          clearTimeout(timeoutHolder)
          if (props.delay === -1) { return }
          setTimeoutHolder(setTimeout(() => {
            if (value !== lastSendValue) {
              props.returnVal(value)
              setLastSendValue(value)
            }
          }, (props.delay === undefined) ? 1000 : props.delay))
        }}
        type={(props.type === 'date' || props.type === 'time') ? props.type : undefined}
        disabled={!!props.disabled}
      />
    }
    {
      props.type === 'textarea' &&
      <textarea
        className={`uncommon w3-border w3-input ${props.className || ''} ${currentValue !== lastSendValue ? 'w3-yellow' : ''}`}
        value={props.multiline ? currentValue.replace(/<br\s*\/?>/g, '\n') : currentValue}
        placeholder={props.placeholder}
        autoFocus={true}
        rows={props.rows || undefined}
        onChange={(event) => {
          const value = props.multiline ? event.target.value.replace(/\r?\n/g, '<br />') : event.target.value
          console.log('onchange', value)
          setCurrentValue(value)
          clearTimeout(timeoutHolder)
          setTimeoutHolder(setTimeout(() => {
            if (value !== lastSendValue) {
              props.returnVal(value)
              setLastSendValue(value)
            }
          }, (props.delay === undefined) ? 1000 : props.delay))
        }}
      />
    }
    {
      props.sendButton &&
      <button
        className={`addButton`}
        onClick={() => {
          props.returnVal(currentValue)
        }}
      >{props.sendButton}</button>
    }
    {
      props.clearButton &&
      <button
        className={`modestButton`}
        onClick={() => {
          setCurrentValue('')
          setLastSendValue('')
          props.returnVal('')
        }}
      ><X /></button>
    }
  </>
}
