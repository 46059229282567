import { Setter } from "../../services/ComService";
import ItemDTO, { IItem } from "./ItemDTO";
import { LocationEditFields } from "../editFields/LocationEditFields";
import { countryDisplay } from "../../services/country";

export default class ItemLocationDTO extends ItemDTO {
  eventId: number = -1
  constructor(o: IItemLocation) {
    super(o)
    this.srcAdd = 'spt/location/add'
    this.srcGet = 'spt/location/get'
    this.srcAddProp = 'spt/location/prop/add'
    this.srcPatchProp = 'spt/location/prop/patch'
    this.srcDelProp = 'spt/location/prop/del'
    this.eventId = o.eventId || -1
    this.editFields = LocationEditFields
    if (this.eventId > -1) {
      this.id = -2
      this.srcGet = 'spt/event/getLocation'
      this.optionsGet = {
        id: this.eventId
      }
    }
  }

  public async addEvent(eventId: number) {
    await Setter('spt/event/addLocation', {
      id: eventId,
      locationId: this.id,
    })
  }

  public getPrintAddress() {
    return `${this.name}
${this.getPropV1('data', 'street') || '?'}
${this.getPropV1('data', 'postalcode') || '?'} ${this.getPropV1('data', 'town') || '?'}
${countryDisplay(this.getPropV1('data', 'country') || '')}`
  }
}

export interface IItemLocation extends IItem {
  eventId?: number,
}

