import { useEffect, useState } from "react";
import ItemPersonDTO from "../../DTO/ItemPersonDTO";
import { EventDTO } from "../../DTO/EventDTO";

export default function PersonCounter(props: {
  items: ItemPersonDTO[],
  parent: EventDTO | undefined,
  className?: string,
  rerenderTrigger?: number,
}) {
  const [count, setCount] = useState(0)
  useEffect(() => {
    console.log('PersonCounter', props.parent, props.items)
    if (props.parent) {
      const key1 = 'participationProp'
      const amount = props.items.filter(item => {
        const key2 = `${item.id}`
        const value = props.parent?.getPropV1(key1, key2) || 'none'
        console.log('PersonCounter', key1, key2, value, value in ['registered', 'participated'])
        return ['registered', 'participated'].includes(value)
      }).length
      setCount(amount)
    } else {
      setCount(props.items.length)
    }
  }, [props.parent, props.items, props.items.length, props.rerenderTrigger])
  return <div className={props.className || 'genericTag'}>{count}</div>
}