import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { Log } from "../../DTO/GetLogs";

enum Status {
  loading,
  done,
}
export default function LogView(props: {
  getLogs: () => Promise<Log[]>,
  className?: string,
}) {
  const [status, setStatus] = useState(Status.loading);
  const [list, setList] = useState<Log[]>([]);
  useEffect(() => {
    props.getLogs().then(logs => {
      setList(logs)
      setStatus(Status.done)
    })
  }, [])
  if (Status.loading === status) {
    return <Spinner />
  }
  return <div className={props.className || ''}>
    <div className='contentHeader'>
      <h2>Log</h2>
    </div>
    <table className='w3-margin'>
      <tbody>
        {
          list.map((log: Log, index) => {
            return <tr key={`log-${index}`} className='PTListItem'>
              <td id={log.origKey}>
                &bull; {log.name}</td>
              <td>{log.displayValue1}</td>
              <td>{log.separator}</td>
              <td>{log.displayValue2}</td>
            </tr>
          })
        }
      </tbody>
    </table>
    {
      list.length === 0 ? 'Keine Einträge' : ''
    }
  </div>
}