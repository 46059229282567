import InputA from "../../components/InputA/InputA";
import { parseStringFromServer, prittifyStringFromServer } from "../../services/TextConverter";
import { TagSelector } from "./tags/TagSelector";
import { TagDTO, TagType } from "../DTO/TagDTO";
import { EditFieldKind, GenericDTO, GenericEditField } from "../DTO/GenericDTO";
import { CanBeDisplayed } from "./materialView";
import Select from "./Select";
import FileManager from "./FileManager/FileManager";
import ItemDTO from "../DTO/ItemDTO";
import { useEffect, useState } from "react";
import { Status } from "./day/dayView";
import { ArrowLeft, ArrowRight, CheckSquare, Copy, Edit, Edit2, Edit3, Plus, Square, Trash, Type } from "react-feather";

import './EditField.scss'
import './TextAreaArray.scss'
import PropDTO from "../DTO/PropDTO";
import { CreateTimeStamp, DisplayDate } from "../../services/DateTime";
import mainservice from "../../services/MainService";
import { PaperOrientation, PaperSizes } from "./PaperSizes";
import InputB from "../../components/InputB/InputB";


export function EditField(props: {
  field: GenericEditField,
  patch: (mf: GenericEditField) => Promise<GenericEditField>,
  item: GenericDTO | ItemDTO,
  editMode: boolean,
  alwaysShow?: boolean,
  noEditButton?: boolean,
  rerenderParent?: () => void,
}) {
  const field = props.field;
  const [status, setStatus] = useState(Status.standard)
  const [fieldValue, setFieldValue] = useState(field.value)
  const [copyToClipBoardState, setCopyToClipBoardState] = useState(0)
  const canBeDisplayed = CanBeDisplayed(props.item.publishKind, field.scope);
  if (field.kind === EditFieldKind.textAreaArray) {
    return <TextAreaArrays
      field={props.field}
      item={props.item}
    />
  }
  if (canBeDisplayed === -1) { return null; }
  if (!props.alwaysShow && (!(
      field.kind === EditFieldKind.textArea ||
      field.kind === EditFieldKind.tag ||
      field.kind === EditFieldKind.multitag ||
      field.kind === EditFieldKind.files
    ) && !props.editMode && (!fieldValue || fieldValue === ''))
  ) {
    return null;
  }
  if (field.kind === EditFieldKind.copyCustomerAddress || field.kind === EditFieldKind.copyPersonAddress || field.kind === EditFieldKind.copyLocationAddress) {
    let toCopy = props.item.getPrintAddress()
    return <h2 className='EditField EditFieldCopyLocationAddress'>
      {props.field.title}
      <button
        className='w3-button'
        onClick={() => {
          navigator.clipboard.writeText(toCopy)
          setCopyToClipBoardState(copyToClipBoardState + 1)
        }}
      >
        <Copy />
      </button>
    </h2>
  }

  if (field.kind === EditFieldKind.check) {
    return <div className='EditField EditFieldCheck'>
      {fieldValue === '1' ? <CheckSquare
        onClick={() => {
          setFieldValue('0')
          props.patch({ ...field, ...{ value: '0' } });
          if (props.rerenderParent) {
            props.rerenderParent()
          }
        }}
      /> : <Square
        onClick={() => {
          setFieldValue('1')
          props.patch({ ...field, ...{ value: '1' } });
          if (props.rerenderParent) {
            props.rerenderParent()
          }
        }}
      />}
      { field.title }
    </div>
  }
  if (field.kind === EditFieldKind.checkTF) {
    return <div className='EditField EditFieldCheck'>
      {fieldValue === 'T' ? <CheckSquare
        onClick={() => {
          setFieldValue('F')
          props.patch({ ...field, ...{ value: 'F' } });
        }}
      /> : <Square
        onClick={() => {
          setFieldValue('T')
          props.patch({ ...field, ...{ value: 'T' } });
        }}
      />}
      { field.title }
    </div>
  }
  return <div className={`EditField w3-col ${props.editMode ? '' : ''} ${field.className || ''}`}>
    <label className='EditFieldLabel'>
      { field.kind === EditFieldKind.textArea &&
        <span className='labelTools'>
          {
            !props.noEditButton &&
            <Edit2
              className='ef-mini-button'
              size={10}
              onClick={() => {
                setStatus(status === Status.edit ? Status.standard : Status.edit)
              }}
            />
          }
          { fieldValue &&
            <>
              <Copy
                className='ef-mini-button'
                size={10}
                onClick={(() => {
                  if (fieldValue) {
                    // navigator.clipboard.writeText(fieldValue)
                    copyToClip(fieldValue)
                    setCopyToClipBoardState(1)
                    setTimeout(() => {
                      setCopyToClipBoardState(0)
                    }, 1000)
                  }
                })}
              />
              <span
                className={`copyToClipBoardButton copyToClipBoardButtonState-${copyToClipBoardState}`}
              >
                Text in die Zwischenablage kopiert
              </span>
            </>
          }
        </span>
      }
      { field.emojiWhenFilled && field.description &&
        <span className='fontSize2' dangerouslySetInnerHTML={{__html: field.emojiWhenFilled}}></span>
      }
      { field.title }
      
    </label>
    {props.editMode && field.description &&
      <div
        className='fieldDescription'
      >{field.description || ''}</div>}
    {!props.editMode && (
      field.kind === EditFieldKind.number ||
      // field.kind === EditFieldKind.textArea ||
      field.kind === EditFieldKind.string
    ) &&
      <div className='textAreaPrint'>
        <span dangerouslySetInnerHTML={{ __html: parseStringFromServer(fieldValue || '') }}></span>
        <span>{field.unit || ''}</span>
      </div>}
    {
      field.kind === EditFieldKind.files && props.item instanceof GenericDTO &&
      <FileManager
        key={`EditFieldFileManager-${props.item.id}`}
        item={props.item}
        readMode={!props.editMode}
      />
    }
    {props.editMode && field.kind === EditFieldKind.string &&
      <InputB
        value={parseStringFromServer(fieldValue || '')}
        returnVal={async (rv) => {
          await props.patch({ ...field, ...{ value: rv } });
          setFieldValue(rv)
          /*
          field.value = rv
          if (props.rerenderParent) {
            props.rerenderParent()
          }
          */
        }}
        disabled={!mainservice.loginService.user.rights.canWrite(field.key1, field.key2)}
      />}
    {props.editMode && (field.kind === EditFieldKind.number || field.kind === EditFieldKind.time || field.kind === EditFieldKind.date) &&
      <InputA
        value={parseStringFromServer(fieldValue || '')}
        returnVal={async (rv) => {
          await props.patch({ ...field, ...{ value: rv } });
          setFieldValue(rv)
        }}
        contentType={EditFieldKind[field.kind] as 'date' | 'time' | 'number'}
        />}
    {field.kind === EditFieldKind.textArea &&
      <>
        {
          (status === Status.edit) ?
          <InputA
            value={parseStringFromServer(fieldValue || '')}
            returnVal={async (rv) => {
              await props.patch({ ...field, ...{ value: rv } });
              setFieldValue(rv)
              setStatus(Status.standard)
            }}
            type={'wysiwyg'} /> : (fieldValue ? <div className='textAreaPrint wysiwyg' dangerouslySetInnerHTML={{__html: parseStringFromServer(fieldValue || '')}}></div> : null)
        }
      </>
    }
    <OldInstance
      editMode={props.editMode}
      field={field}
      item={props.item}
      rerenderParent={(setValue) => {
        field.value = setValue
        setFieldValue(setValue)
        if (props.rerenderParent) {
          props.rerenderParent()
        }
        console.log('upProp setFieldValue', setValue, field)
        
      }}
    />
    {props.editMode && field.kind === EditFieldKind.dropDown &&
      <Select
        value={parseStringFromServer(fieldValue || '')}
        returnVal={(rv) => {
          props.patch({ ...field, ...{ value: rv } });
        }}
        options={field.options || []}
      />
    }
    {(field.kind === EditFieldKind.tag || field.kind === EditFieldKind.multitag) && (props.item instanceof GenericDTO || props.item instanceof ItemDTO) &&
      <TagSelector
        parentId={props.item.id}
        kind={field.key2 as TagType}
        mode={(field.kind === EditFieldKind.tag) ? 'single' : 'list'}
        selectedTags={props.item.getTagsByKind(field.key2 as TagType, field.key1)}
        selectTag={async (tag: TagDTO, single: boolean) => {
          console.log('rerender plz selectTag', props.rerenderParent)
          await props.item.setTag(tag, single, field.key1);
          if (props.rerenderParent) { props.rerenderParent() }
        }}
        unsetTag={async (tag: TagDTO) => {
          console.log('rerender plz unsetTag')
          await props.item.unsetTag(tag, field.key1);
          if (props.rerenderParent) { props.rerenderParent() }
        }}
        readMode={!props.editMode}
      />
    }
  </div>;
}

function OldInstance(props: {
  editMode: boolean,
  field: GenericEditField,
  item: GenericDTO | ItemDTO,
  rerenderParent?: (setValue: string) => void,
}) {
  const field = props.field
  const item = props.item
  const editMode = props.editMode
  if (
    !(item instanceof GenericDTO) ||
    !editMode ||
    !(field.kind === EditFieldKind.string || field.kind === EditFieldKind.number || field.kind === EditFieldKind.textArea)
  ) { return null }
  const localPropId = item.localPropId(props.field.key1, props.field.key2)
  const upProp = item.getUpProp(props.field.key1, props.field.key2)
  // if upProp is null - there is no up to compare with
  console.log('upProp', localPropId, upProp)
  if (localPropId === -1 || upProp === null) { return null }
  return <>
    <Type className='hover-show-next' />
    <div className='hover-show-next-target'>
      {
        upProp &&
        <div>Wert aus Vorlage:
          <div className='w3-padding w3-border w3-margin-top w3-margin-bottom' dangerouslySetInnerHTML={{__html: parseStringFromServer(upProp.value1)}}>
          </div>
        </div>
      }
      {
        localPropId > -1 &&
        <button
          onClick={async () => {
            await item.removeProp(localPropId)
            if (props.rerenderParent) {
              props.rerenderParent(upProp?.value1 || '')
            }
          }}
        >Lokale Änderung verwerfen</button>
      }
    </div>
  </>
}

const StandardManuskriptText = parseStringFromServer(`<h2 styleEQUALMARKDOUBLEQUOTEMARKtextMINUSMARKalignDOUBLEPOINTMARK centerSEMICOLONMARKDOUBLEQUOTEMARK>Überschrift<SLASHMARKh2><p styleEQUALMARKDOUBLEQUOTEMARKtextMINUSMARKalignDOUBLEPOINTMARK centerSEMICOLONMARKDOUBLEQUOTEMARK>Text<SLASHMARKp><p styleEQUALMARKDOUBLEQUOTEMARKtextMINUSMARKalignDOUBLEPOINTMARK centerSEMICOLONMARKDOUBLEQUOTEMARK><br><SLASHMARKp>`)

export function TextAreaArrays(props: {
  field: GenericEditField,
  item: GenericDTO | ItemDTO,
}) {
  const item = props.item
  const [orders, setOrders] = useState(item.getProps('moderationCardSetOrder'))
  const [status, setStatus] = useState(Status.standard)
  function reloadOrders() {
    setOrders(item.getProps('moderationCardSetOrder'))
    setStatus(Status.standard)
  }
  useEffect(() => {
    reloadOrders()
  }, [props.item])
  if (status === Status.loading) {
    return null
  }
  return <div className='contentPart'>
    <div className='contentHeader'>
      <h2>Manuskripte</h2>
    </div>
    <div className='w3-padding'>
      <button
        className='w3-button w3-green'
        onClick={async () => {
          setStatus(Status.loading)
          const key2 = CreateTimeStamp()
          const name = DisplayDate(new Date())
          const newName = new Date().getTime() + ''
          await item.addProp('moderationCardSet', newName, StandardManuskriptText)
          await item.addProp('moderationCardSetOrder', key2, newName, name)
          await item.addProp('moderationCardPaperSize', key2, 'A4')
          reloadOrders()
        }}
      >+ Manusskript hinzufügen</button>
      {
        orders.map(o => {
          const key2 = o.key2
          return <TextAreaArray
            key={`TextAreaArrays-order-${key2}`}
            field={props.field}
            item={item}
            order={o}
            trash={async () => {
              await item.removeProp(o.id)
              reloadOrders()
            }}
          />
        })
      }
    </div>
  </div>
}

export function TextAreaArray(props: {
  field: GenericEditField,
  item: GenericDTO | ItemDTO,
  order: PropDTO,
  trash: () => void,
}) {
  const key1 = 'moderationCardSet'
  const item = props.item
  const [order, setOrder] = useState(parseStringFromServer(props.order.value1).split(';').filter(o => o !== ''))
  const [page, setPage] = useState(0)
  const currentCardName = order[page]
  const [name, setName] = useState(parseStringFromServer(props.order.value2))
  // const [status, setStatus] = useState(Status.standard)
  const [editCard, setEditCard] = useState('')
  const [status, setStatus] = useState(Status.standard)
  const [cardPaperOrientation, setCardPaperOrientation] = useState(item.getPropV1('moderationCardPaperOrientation', props.order.key2) || 'landscape')
  async function addCard(index: number) {
    const newName = new Date().getTime() + ''
    await item.addProp(key1, newName, StandardManuskriptText)
    order.splice(index, 0, newName)
    await item.addProp(props.order.key1, props.order.key2, order.join(';'), name)
    setEditCard(newName)
    setPage(index)
  }
  async function remCard(o: string) {
    if (!window.confirm('Karte wirklich löschen?')) { return }
    const p = item.getProp(key1, o)
    if (p) {
      await item.removeProp(p.id)
      await item.addProp(props.order.key1, props.order.key2, order.filter(or => or !== o).join(';'))
      setEditCard(o + '-del')
      setPage(Math.min(order.length - 2, page))
      setOrder(parseStringFromServer(props.order.value1).split(';').filter(o => o !== ''))
    }
  }
  function increasePage () {
    setPage(Math.min(order.length - 1, page + 1))
  }
  function decreasePage() {
    setPage(Math.max(0, page - 1))
  }
  async function trash() {
    // if (!window.confirm('Dieses Deck wirklich löschen?')) { return }
    // if (window.confirm('Deck doch behalten?')) { return }
    // We have to delete everything
    // Remove every single entry
    for(let i = 0, m = order.length; i < m; i++) {
      let p = item.getProp(key1, order[i])
      if (p) {
        await item.removeProp(p.id)
      }
    }
    props.trash()
  }
  
  const cardContent = item.getPropV1(key1, currentCardName) || ''
  const cardPaperSize = item.getPropV1('moderationCardPaperSize', props.order.key2) || 'A4'
  
  return <div className='TextAreaArray'>
    <h2>
      {name} {status === Status.standard &&
        <>
          <Edit
            className='pointer'
            onClick={() => {
              setStatus(Status.editName)
            }}
          />
          <Trash
            className='pointer'
            onClick={trash}
          />
          <div>Format</div>
          <Select
            options={PaperSizes.map((ps) => {
              return {
                title: ps.name,
                value: ps.key
              }
            })}
            value={cardPaperSize}
            returnVal={(nv) => {
              item.addProp('moderationCardPaperSize', props.order.key2, nv)
            }}
          />
          <div>Ausrichtung</div>
          <Select
            options={PaperOrientation.map((ps) => {
              return {
                title: ps.name,
                value: ps.key
              }
            })}
            value={cardPaperOrientation}
            returnVal={(nv) => {
              item.addProp('moderationCardPaperOrientation', props.order.key2, nv)
              setCardPaperOrientation(nv)
            }}
          />
        </>
      }
    </h2>
    {
      status === Status.editName &&
      <InputA
        value={name}
        returnVal={async (rv) => {
          await item.addProp(props.order.key1, props.order.key2, order.join(';'), rv)
          setName(rv)
          setStatus(Status.standard)
        }}
      />
    }

    <div
      className={`flex`}
    >
      <div className={`leftButtons flex flex-column`}>
        <button onClick={() => { addCard(page) }}>
          <Plus />
        </button>
        {
          0 < page &&
          <button onClick={decreasePage}><ArrowLeft /></button>
        }
      </div>
      <div
        className={`TextAreaArrayItem flex-grow TextAreaArrayItem-${cardPaperOrientation}`}
      >
        <Edit
          className='pointer'
          onClick={() => {
            setEditCard(currentCardName)
          }}
        />
        <Trash
          className='pointer'
          onClick={async () => {
            remCard(currentCardName)
          }}
        />
        {
          (currentCardName === editCard) ?
          <InputA
            value={parseStringFromServer(cardContent || '')}
            returnVal={async (rv) => {
              await item.addProp(key1, currentCardName, rv)
              setEditCard('')
            }}
            type={'wysiwyg'} /> : <div className={`TextArearArrayItemCard ${cardPaperOrientation}`}>
              <div dangerouslySetInnerHTML={{__html: prittifyStringFromServer(cardContent || '')}}></div>
              <div className={`page-number`}>{page + 1}</div>
            </div>
          }
      </div>
      <div className={`rightButtons flex flex-column`}>
        <button onClick={() => { addCard(page + 1) }}>
          <Plus />
        </button>
        { page < order.length - 1 && order[page + 1] &&
          <button onClick={increasePage}><ArrowRight /></button>
        }
      </div>
    </div>
  </div>
}

function copyToClip(str: string): void {
  function listener(e: ClipboardEvent): void {
    e.clipboardData?.setData("text/html", str)
    e.clipboardData?.setData("text/plain", str)
    e.preventDefault()
  }

  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
}
